class A11y {
  constructor(selectors = {}) {
    this.selectors = Object.assign({
      mobileMenu: '.',
      panel: '.a11y-panel',
      collapseMenu: '.a11y-collapse-menu',
      collapseButton: '.mobile-footer-icons .a11y-collapse-button',
      collapseButtonClose: '.a11y-collapse-menu .close',
    }, selectors);

    this.key = 'a11y';
    this.panels = [...document.querySelectorAll(this.selectors.panel)];
    this.state = Object.assign({
      animations: true,
    }, JSON.parse(window.localStorage.getItem(this.key)) || {});

    this.#init_panels();
    this.#init_collapse_menu();
    this.#init_state();
  }

  #set_prop(prop, state) {
    this.state[prop] = state;
    this.#activate_link(prop, state);
    this.#save();
  }

  #save() {
    window.localStorage.setItem(this.key, JSON.stringify(this.state));
  }

  #init_collapse_menu() {
    const menu = () => document.querySelector(this.selectors.collapseMenu);
    const button = () => document.querySelector(this.selectors.collapseButton);
    const button_close = () => document.querySelector(this.selectors.collapseButtonClose);

    trapFocus && trapFocus(menu());

    button()?.addEventListener('click', e => {
      menu().classList.add('in');
      button().ariaExpanded = 'true';
      setTimeout(() => button_close().focus(), 500);
    });

    button_close()?.addEventListener('click', e => {
      const btn = button();
      menu().classList.remove('in');
      btn.ariaExpanded = 'false';
      btn.focus();
    });
  }

  #init_panels() {
    this.panels.forEach(panel => {
      [...panel.querySelectorAll('[data-action]')].forEach(link => {
        ['click', 'keydown'].forEach(event_name => {
          link.addEventListener(event_name, e => {
            if (e.type === 'keydown' && ![13, 32].includes(e.keyCode)) {
              return;
            }

            e.preventDefault();
            this.toggle(e.target.dataset.action);
          });
        });
      });
    });
  }

  #init_state() {
    window.addEventListener('load', () => {
      Object.keys(this.state).forEach(prop => this.toggle(prop, this.state[prop]));
    });
  }

  toggle(prop, state = null) {
    const func = `toggle_${prop}`;
    state = state ?? !this.state[prop];

    if (!this[func]) {
      return;
    }

    this[func](state);
    this.#set_prop(prop, state);
  }

  toggle_animations(state) {
    document.body.classList[state ? 'remove' : 'add']('a11y-stop-animations');

    // Play or pause videos on page.
    [...document.querySelectorAll('video')].forEach(video => {
      if (state && !video.autoplay) {
        return;
      }

      video[state ? 'play' : 'pause']();
    });

    // Play or pause sliders.
    [...document.querySelectorAll('.slick-initialized')].forEach(slick => {
      const $slick = jQuery(slick);

      if ($slick.slick('slickGetOption', 'autoplay')) {
        $slick.slick(state ? 'slickPlay' : 'slickPause');
      }
    });

    /**
     * @deprecated 1.1.2
     */
    [...document.querySelectorAll('.splide.is-initialized')].forEach(splide => {
      const {Autoplay, AutoScroll} = splide.splide?.Components;

      if (splide.splide.options.autoplay) {
        Autoplay[state ? 'play' : 'pause']();
      }

      if (splide.splide.options.autoScroll) {
        AutoScroll[state ? 'play' : 'pause']();
      }
    });
  }

  #activate_link(prop, state) {
    this.panels.forEach(panel => {
      const link = panel.querySelector(`[data-action="${prop}"]`);
      link.classList[!state ? 'add' : 'remove']('active');
      link.ariaPressed = !state;
    });
  }
}
